import { Pictogram } from '@reverse-hr/pattern-library';
import { useDrivingTime } from '../../../../../utils/hooks/application/use-driving-time';
import type { TEligibleApplication } from '../../../../../models/EligibleApplication';
import type { FC } from 'react';
import { Trans } from 'react-i18next';
import { utilityGetTranslatedLocation } from '../../../../../utils/location';
import { useStoreState } from 'easy-peasy';
import { selectorUser } from '../../../../../model/User';

interface TLocationProps {
  candidateAddress: TEligibleApplication['candidateAddress'];
  candidateDistance: TEligibleApplication['candidateDistance'];
  candidateDrivingDistanceInKilometers: TEligibleApplication['candidateDrivingDistanceInKilometers'];
  candidateDrivingDistanceInSeconds: TEligibleApplication['candidateDrivingDistanceByCarInSeconds'];
}

const Location: FC<TLocationProps> = ({
  candidateAddress,
  candidateDistance,
  candidateDrivingDistanceInKilometers,
  candidateDrivingDistanceInSeconds,
}) => {
  const { locale } = useStoreState(selectorUser);
  const drivingTime = useDrivingTime(candidateDrivingDistanceInSeconds);
  const distanceToDisplay =
    candidateDrivingDistanceInKilometers ?? candidateDistance;

  const parsedDistance =
    typeof distanceToDisplay === 'number' ? distanceToDisplay.toFixed(0) : null;

  const translatedLocation = utilityGetTranslatedLocation({
    language: locale,
    location: candidateAddress,
  });

  const locationContent =
    translatedLocation?.city || translatedLocation?.address;

  return (
    <td className="c-app-showcase__cell c-app-showcase__cell--left-aligned c-app-showcase__cell--top-aligned">
      <Pictogram
        icon="icn-map-outline-24"
        modifier="u-color-primary-80"
        size="small"
      >
        <Trans
          context={parsedDistance !== null ? 'withDistance' : ''}
          i18nKey="showcase.location.locationAndDistance"
          values={{
            location: locationContent,
            distance: parsedDistance,
          }}
        />
      </Pictogram>

      {!!drivingTime ? (
        <Pictogram
          icon="icn-clock-24"
          modifier="u-color-primary-80"
          size="small"
        >
          {drivingTime}
        </Pictogram>
      ) : null}
    </td>
  );
};

export default Location;

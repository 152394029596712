export const SEARCHING_FR = {
  header: {
    title: 'Recherche',
    subtitle:
      "Sur cette page, vous trouverez toutes les données de notre recherche de candidats : le nombre de personnes contactées, à combien de personnes nous avons parlé et le nombre d'intéressées.",
    subtitle_done: '',
  },
  numbers: {
    title_profilesCount: 'Contactés',
    title_profilesWithJobInterviewReportCount: 'Interviewés',
    title_interestedProfilesCount: 'Intéressés',
    title_interestedWithConcerns:
      'Candidats éligibles qui déclinent pour les raisons suivantes:',
    description_profilesCount:
      'Les personnes que nous avons trouvées en adéquation avec le candidat idéal et que nous avons contactées.',
    description_profilesWithJobInterviewReportCount:
      'Les personnes que nous avons réussi à impliquer dans le premier entretien.',
    description_interestedProfilesCount:
      "Les personnes qui ont exprimé leur intérêt pour l'offre. Nous avons échangé avec la plupart d'entre eux, ou nous avons organisé le premier entretien.",
    description_brand: 'Marque',
    description_role: 'Rôle',
    description_compensation: 'Rémunération',
    description_location: 'Localisation',
  },
  daysCard: {
    'intro_to-do': 'après',
    intro_doing: 'dans les',
    daysNumber: '10',
    days: 'jours',
    days_one: 'jour',
    'body_to-do':
      'suivant la signature du contrat, vous pourrez vérifier toutes les informations',
    body_doing: 'vous pourrez voir toutes les données',
  },
  jraCard: {
    title: 'Le Portrait du Marché',
    subtitle:
      'Le document décrivant les activités de recherche et les analyses de marché.',
    download: 'Télécharger',
  },
  tasks: {
    title:
      'Vous voulez savoir comment se déroule la recherche de candidats? <strong>Voici mes dernières activités</strong>',
    viewLabel_grid: 'Visualisation grille',
    viewLabel_list: 'Visualisation liste',
    card: {
      date: '$t(common.formatting.date.dateFormat_time) - $t(common.formatting.date.dateFormat)',
      task: '',
      taskDescription: '',
      taskDescription_job_interview_reports_filled_in:
        'a interviewé {{ count }} personnes',
      taskDescription_profiles_added: 'a fait {{ count }} activités de chasse',
      taskDescription_application_set_first_day_on_the_job:
        'a indiqu\u00e9 la date de d\u00e9but de {{ candidateFullName }}',
      taskDescription_application_handle_client_interview_feedback:
        'a facilit\u00e9 la d\u00e9cision sur {{ candidateFullName }}',
      taskDescription_application_handle_additional_client_interviews:
        'ha preparato il colloquio con {{ candidateFullName }}',
      taskDescription_application_help_negotiate_offer:
        'a facilit\u00e9 la n\u00e9gociation avec {{ candidateFullName }}',
      taskDescription_application_present_offer_to_candidate:
        'a facilit\u00e9 l\u0027embauche de {{ candidateFullName }}',
      taskDescription_application_schedule_client_interview:
        'a programm\u00e9 l\u0027entretien entre {{ companyName }} et {{ candidateFullName }}',
      taskDescription_application_ask_for_client_feedback_on_curriculum:
        'a obtenu un retour sur {{ candidateFullName }}',
      taskDescription_application_prepare_for_additional_client_interview:
        'a programm\u00e9 l\u0027entretien entre {{ companyName }} et {{ candidateFullName }}',
      taskDescription_application_handle_additional_client_interview_outcome:
        'ha contattato {{ candidateFullName }} per informarsi sul colloquio in azienda',
      taskDescription_application_prepare_for_client_interview:
        'a programm\u00e9 l\u0027entretien entre {{ companyName }} et {{ candidateFullName }}',
      taskDescription_application_handle_client_interview_outcome:
        'ha contattato {{ candidateFullName }} per informarsi sul colloquio in azienda',
      taskDescription_application_schedule_client_interview_after_scout_report:
        'a programm\u00e9 l\u0027entretien entre {{ companyName }} et {{ candidateFullName }}',
      taskDescription_application_notify_client_of_offer_refusal:
        'ha comunicato a {{ companyName }} che {{ candidateFullName }} si \u00e8 ritirato',
      taskDescription_application_check_in_day_before_new_job:
        'a souhait\u00e9 bonne chance \u00e0 {{ candidateFullName }}',
      taskDescription_application_check_in_after_fifteen_days_on_new_job:
        'a demand\u00e9 un retour \u00e0 {{ candidateFullName }} sur sa nouvelle exp\u00e9rience',
      taskDescription_application_check_in_after_fifty_days_on_new_job:
        'ha chiesto un ulteriore feedback a {{ candidateFullName }} sulla sua nuova esperienza',
      taskDescription_application_prepare_for_scout_interview:
        'a organis\u00e9 l\u0027entretien entre le scout et {{ candidateFullName }}',
      taskDescription_application_handle_client_dislike:
        'a demand\u00e9 la raison du rejet de {{ candidateFullName }}',
      taskDescription_application_ask_for_scout_report:
        'a re\u00e7u et analys\u00e9 le rapport du talent scout',
      taskDescription_application_approve_scout_report:
        'a fait avancer {{ candidateFullName }} apr\u00e8s l\u0027entretien avec le talent scout',
      taskDescription_job_send_profiling_meeting_recap_email:
        'a envoy\u00e9 un compte-rendu du profilage',
      taskDescription_job_schedule_presentation_meeting:
        'a fix\u00e9 la date de l\u0027analyse de march\u00e9',
      taskDescription_job_contact_client_after_profiling_meeting:
        'a envoy\u00e9 un email avec un lien permettant l\u0027acc\u00e8s \u00e0 la plateforme',
      taskDescription_job_prepare_for_presentation_meeting:
        'a envoy\u00e9 des candidats au client',
      taskDescription_job_hunt_candidates:
        'a publi\u00e9 l\u0027annonce sur diff\u00e9rents canaux',
      taskDescription_job_find_scout: 'a choisi le talent scout',
      taskDescription_job_schedule_profiling_meeting:
        'a programm\u00e9 la visioconf\u00e9rence de profiling',
      taskDescription_job_prepare_job_post:
        'a publi\u00e9 l\u0027annonce sur la plateforme Reverse',
      taskDescription_job_prepare_for_profiling_meeting:
        'a pr\u00e9par\u00e9 la visioconf\u00e9rence de profiling',
    },
  },
};
